import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './conteudos.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, getDoc, doc, updateDoc, collection, query, where, getDocs, setDoc } from 'firebase/firestore';
import Loading from '../../components/Loading';
import Avatar from '../../assets/img/woman.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

function PositiveClass({ setNotification }) {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [classDetails, setClassDetails] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [completedVideos, setCompletedVideos] = useState([]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const { id } = useParams();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const getUserName = async (email) => {
    const db = getFirestore();
    const userCollections = ['userBasic', 'userPremium', 'userMasters'];

    for (const collectionName of userCollections) {
      const q = query(collection(db, collectionName), where('email', '==', email));

      try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDocData = querySnapshot.docs[0].data();
          const firstName = userDocData.nome.split(' ')[0];
          const lastName = userDocData.nome.split(' ')[1];

          const userName = `${firstName} ${lastName}`;
          return userName;
        }
      } catch (error) {
        setNotification({ message: 'Erro ao carregar.', type: 'error' });
      }
    }

    return null;
  };

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const db = getFirestore();
        const classDoc = doc(db, 'positiveClasses', id);
        const classSnapshot = await getDoc(classDoc);

        if (classSnapshot.exists()) {
          setClassDetails({ id: classSnapshot.id, ...classSnapshot.data() });

          // Carregar comentários do Firestore
          const commentsData = classSnapshot.data().comments || [];
          setComments(commentsData);

          // Carregar vídeos concluídos
          if (user) {
            const userCompletionDocRef = doc(db, 'positiveClasses', id, 'userCompletions', user.uid);
            const userCompletionDocSnapshot = await getDoc(userCompletionDocRef);

            if (userCompletionDocSnapshot.exists()) {
              const userData = userCompletionDocSnapshot.data();
              setCompletedVideos(userData.completedVideos || [false]);
            }
          }
        }
      } catch (error) {
        setNotification({ message: 'Erro ao carregar Positive Class, tente novamente.', type: 'error' });
      }
    };

    if (user) {
      fetchClassDetails();
    }
  }, [id, user, setNotification]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getFirestore();
      const classDoc = doc(db, 'positiveClasses', id);

      // Obter nome do usuário logado
      const userEmail = user ? user.email : null;
      const userName = userEmail ? await getUserName(userEmail) : 'Nome da Pessoa';

      // Atualizar comentários no Firestore
      await updateDoc(classDoc, {
        comments: [...comments, {
          name: userName,
          date: new Date().toLocaleDateString(),
          comment: newComment,
        }],
      });

      // Atualizar o estado dos comentários para refletir o novo comentário imediatamente
      setComments([...comments, {
        name: userName,
        date: new Date().toLocaleDateString(),
        comment: newComment,
      }]);

      // Limpar o campo de novo comentário
      setNewComment('');
    } catch (error) {
      setNotification({ message: 'Erro ao adicionar comentário.', type: 'error' });
    }
  };

  // Marcar vídeo como concluído
  const handleVideoCompletion = async (index) => {
    if (user) {
      const updatedCompletedVideos = [...completedVideos];
      updatedCompletedVideos[index] = !completedVideos[index];

      try {
        const db = getFirestore();
        const userId = user.uid;
        const userCompletionDocRef = doc(db, 'positiveClasses', id, 'userCompletions', userId);
        await setDoc(userCompletionDocRef, { completedVideos: updatedCompletedVideos }, { merge: true });
        setCompletedVideos(updatedCompletedVideos);
      } catch (error) {
        setNotification({ message: 'Erro ao marcar vídeo como concluído.', type: 'error' });
      }
    }
  };


  if (!classDetails) {
    return <Loading />;
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="container">
        <Header toggleSidebar={toggleSidebar} />
        <div className="padding" />

        <div className="content">
          <div className="video-class-container">
            <div className="video-class-description">
              <h2>{classDetails.title}</h2>
              <p>{classDetails.description}</p>
            </div>

            <div className="div-class-video">
              <video controls playsInline>
                <source src={classDetails.videoURL} type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
              </video>
              <h2>{classDetails.videoTitle}</h2>
              <div>
                <button onClick={() => handleVideoCompletion(0)}>
                  {completedVideos[0] ? (
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                  ) : (
                    <FontAwesomeIcon icon={faCircle} style={{ color: '#ca60d6' }} />
                  )}
                  <span style={{ marginLeft: '0.5rem' }}>
                    {completedVideos[0] ? 'Concluído' : 'Marcar como concluído'}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="comentarios">
              <span>{comments.length} Comentário(s)</span>
              <form onSubmit={handleCommentSubmit}>
                <img src={Avatar} alt="Avatar" />
                <label htmlFor="comment">Deixe seu comentário:</label>
                <textarea
                  id="comment"
                  name="comment"
                  value={newComment}
                  required
                  onChange={handleCommentChange}
                />
                <button type="submit">Publicar</button>
              </form>

              <div className="comments-section">
                {comments.map((comment, index) => (
                  <div key={index} className="comment">
                    <img src={Avatar} alt="Avatar" />
                    <div className="comment-details">
                      <span className="comment-name">{comment.name}</span>
                      <span className="comment-date">{comment.date}</span>
                      <p className="comment-text">{comment.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PositiveClass;