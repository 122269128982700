import { Routes, Route} from "react-router-dom";
import Login from '../pages/Login';
import Register from "../pages/Register";
import Home from '../pages/Home';
import Private from "./Private";
import Administrador from "../pages/Administrador";
import Encontros from "../pages/Encontros";
import Albuns from "../pages/Encontros/albuns";
import RodaMpm from "../pages/Roda";
import Conteudos from "../pages/Conteudos";
import Curso from "../pages/Conteudos/curso";
import PositiveClass from "../pages/Conteudos/class";
import DadosUsuario from "../pages/DadosUsuario";
import BoasVindasCard from "../pages/Conteudos/boasVindas";
import ResetPassword from "../pages/Login/resetPassword";
import Ranking from "../pages/Ranking";

function RoutesApp({setNotification}) {
  return (
    <Routes>
      {/* Liberado para todos os usuários */}
      <Route path="/reset-password" element={<ResetPassword setNotification={setNotification}/>} />
      <Route path="/" element={<Login setNotification={setNotification}/>} />
      <Route path="/home" element={<Private><Home setNotification={setNotification}/></Private>} />
      <Route path="/albuns" element={<Private><Albuns setNotification={setNotification}/></Private>} />
      <Route path="/encontros" element={<Private><Encontros setNotification={setNotification}/></Private>} />
      <Route path="/conteudos" element={<Private><Conteudos setNotification={setNotification}/></Private>} />
      <Route path="/curso/:id" element={<Private><Curso setNotification={setNotification}/></Private>} />
      <Route path="/class/:id" element={<Private><PositiveClass setNotification={setNotification}/></Private>} />
      <Route path="/dados-usuario" element={<Private><DadosUsuario setNotification={setNotification}/></Private>} />
      <Route path="/boas-vindas" element={<Private><BoasVindasCard setNotification={setNotification}/></Private>} />
      <Route path="/ranking" element={<Private><Ranking setNotification={setNotification}/></Private>} />

      {/* Liberado para master e premium */}
      <Route path="/roda-da-mulher-positiva" element={<Private allowedUserTypes={["userMasters", "userPremium"]}><RodaMpm setNotification={setNotification}/></Private>} />

      {/* Liberado somente para master */}
      <Route path="/registrar" element={<Private allowedUserTypes={["userMasters"]}><Register setNotification={setNotification}/></Private>} />
      <Route path="/administrador" element={<Private allowedUserTypes={["userMasters"]}><Administrador setNotification={setNotification}/></Private>} />
    </Routes>
  );
}

export default RoutesApp;
