import React, { useState } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar'
import { getFirestore, collection, addDoc, serverTimestamp, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './administrador.css';


function Administrador({ setNotification }) {
  const [isOpen, setIsOpen] = useState(false);

  const [videoBoasVindas, setVideoBoasVindas] = useState(null);
  const [capa, setCapa] = useState(null);
  const [uploadingBoasVindas, setUploadingBoasVindas] = useState(false);

  const [newEncontroTitle, setNewEncontroTitle] = useState('');
  const [newEncontroPhoto, setNewEncontroPhoto] = useState(null);
  const [newEncontroDescription, setNewEncontroDescription] = useState('');
  const [newEncontroData, setNewEncontroData] = useState('');
  const [uploadingNewEncontro, setUploadingNewEncontro] = useState(false);

  const [video, setVideo] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);

  const [album, setAlbum] = useState('');
  const [photo, setPhoto] = useState(null);
  const [uploadingfoto, setUploadingfoto] = useState(false);

  const [capaNovidade, setCapaNovidade] = useState(null);
  const [titleNovidade, setTitleNovidade] = useState('');
  const [descriptionNovidade, setDescriptionNovidade] = useState('');
  const [uploadingNovidade, setUploadingNovidade] = useState(false);

  const [videos, setVideos] = useState([{ title: '', video: null }]);
  const [pdfAula, setPdfAula] = useState(null);
  const [capaCurso, setCapaCurso] = useState(null);
  const [videoAulaTitle, setVideoAulaTitle] = useState('');
  const [videoAulaDescription, setVideoAulaDescription] = useState('');
  const [uploadingVideoAula, setUploadingVideoAula] = useState(false);

  const [positiveClassTitle, setPositiveClassTitle] = useState('');
  const [positiveClassDescription, setPositiveClassDescription] = useState('');
  const [positiveClassCover, setPositiveClassCover] = useState(null);
  const [positiveClassVideo, setPositiveClassVideo] = useState(null);
  const [positiveClassVideoTitle, setPositiveClassVideoTitle] = useState('');
  const [uploadingPositiveClass, setUploadingPositiveClass] = useState(false);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // boas vindas
  const handleVideoChange = (e) => {
    setVideoBoasVindas(e.target.files[0]);
  };

  const handleCapaChange = (e) => {
    setCapa(e.target.files[0]);
  };

  const handleUploadBoasVindas = async () => {
    if (!videoBoasVindas || !capa || uploadingBoasVindas) {
      setNotification({ message: 'Nenhum arquivo selecionado', type: 'warning' })
      return;
    }

    setUploadingBoasVindas(true);
    const storage = getStorage();
    const storageRef = ref(storage, `boas-vindas/${videoBoasVindas.name}`);

    try {
      const videoSnapshot = await uploadBytes(storageRef, videoBoasVindas);
      const videoUrl = await getDownloadURL(videoSnapshot.ref);

      const capaStorageRef = ref(storage, `boas-vindas/${capa.name}`);
      const capaSnapshot = await uploadBytes(capaStorageRef, capa);
      const capaUrl = await getDownloadURL(capaSnapshot.ref);

      const firestore = getFirestore();
      const boasVindasRef = collection(firestore, 'BoasVindas');

      await addDoc(boasVindasRef, {
        videoUrl,
        capaUrl,
        timestamp: serverTimestamp(),
      });

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })
      setVideoBoasVindas(null);
      setCapa(null);
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingBoasVindas(false);
    }
  };

  // Proximo Encontro
  const handleUploadNewEncontro = async () => {
    if (!newEncontroTitle || !newEncontroPhoto || !newEncontroDescription || !newEncontroData || uploadingNewEncontro) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })

      return;
    }

    setUploadingNewEncontro(true);

    const storage = getStorage();
    const storageRef = ref(storage, `encontros/${newEncontroPhoto.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, newEncontroPhoto);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const firestore = getFirestore();
      const encontroRef = doc(firestore, 'encontros', 'YSh69esB6zqdGuD8y5B7');

      // Verifica se o documento existe
      const docSnapshot = await getDoc(encontroRef);

      if (docSnapshot.exists()) {
        // Documento existe, então podemos atualizá-lo
        await updateDoc(encontroRef, {
          title: newEncontroTitle,
          photoURL: downloadURL,
          description: newEncontroDescription,
          data: newEncontroData,
        });
      } else {
        // Documento não existe, então podemos criá-lo
        await setDoc(encontroRef, {
          title: newEncontroTitle,
          photoURL: downloadURL,
          description: newEncontroDescription,
          data: newEncontroData,
        });
      }

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setNewEncontroTitle('');
      setNewEncontroPhoto(null);
      setNewEncontroDescription('')
      setNewEncontroData('')
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingNewEncontro(false);
    }
  };


  //Novidades
  const handleFileChangeNovidade = (e) => {
    const file = e.target.files[0];
    setCapaNovidade(file);
  };

  const handleUploadNovidade = async () => {
    if (!capaNovidade || !titleNovidade || !descriptionNovidade || uploadingNovidade) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })

      return;
    }

    setUploadingNovidade(true);
    const storage = getStorage();
    const storageRef = ref(storage, `novidades/${capaNovidade.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, capaNovidade);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const firestore = getFirestore();
      await addDoc(collection(firestore, 'novidades'), {
        title: titleNovidade,
        description: descriptionNovidade,
        url: downloadURL,
        timestamp: serverTimestamp(),
      });

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setCapaNovidade(null);
      setTitleNovidade('');
      setDescriptionNovidade('');
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingNovidade(false);
    }
  };

  //videos
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideo(file);
  };

  const handleUpload = async () => {
    if (!video || !title || !description || uploading) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })

      return;
    }

    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `videos/${video.name}`);

    try {
      const snapshot = await uploadBytes(storageRef, video);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const firestore = getFirestore();
      await addDoc(collection(firestore, 'videos'), {
        title,
        description,
        url: downloadURL,
        timestamp: serverTimestamp(),
      });

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setVideo(null);
      setTitle('');
      setDescription('');
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploading(false);
    }
  };

  //fotos
  const handleAlbumChange = (e) => {
    setAlbum(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const files = e.target.files;
    setPhoto(files);
  };

  const handleUploadfotos = async () => {
    if (!album || !photo || uploadingfoto) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })

      return;
    }

    setUploadingfoto(true);

    const storage = getStorage();
    const firestore = getFirestore();

    try {
      const uploadTasks = Array.from(photo).map(async (photo) => {
        const storageRef = ref(storage, `photos/${album}/${photo.name}`);
        const snapshot = await uploadBytes(storageRef, photo);
        const downloadURL = await getDownloadURL(snapshot.ref);
  
        await addDoc(collection(firestore, `albums/${album}/photos`), {
          albumName: album,
          coverURL: downloadURL,
          timestamp: serverTimestamp(),
        });
      });
  
      await Promise.all(uploadTasks);

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setPhoto(null);
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingfoto(false);
    }
  };


  // Video Aula
  const handleVideoTitleChange = (e, index) => {
    const { value } = e.target;
    const updatedVideos = [...videos];
    updatedVideos[index].title = value;
    setVideos(updatedVideos);
  };

  const handleVideoFileChange = (e, index) => {
    const file = e.target.files[0];
    const updatedVideos = [...videos];
    updatedVideos[index].video = file;
    setVideos(updatedVideos);
  };

  const addVideoField = () => {
    setVideos([...videos, { title: '', video: null }]);
  };

  const handleFileChangePdfAula = (e) => {
    const file = e.target.files[0];
    setPdfAula(file);
  };

  const handleFileChangeCapaCurso = (e) => {
    const file = e.target.files[0];
    setCapaCurso(file);
  };

  const handleUploadVideoAula = async () => {
    if (!videos.every(video => video.video && video.title) || uploadingVideoAula || !pdfAula || !capaCurso) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })

      return;
    }

    setUploadingVideoAula(true);

    try {
      const storage = getStorage();
      const firestore = getFirestore();

      const videosData = [];

      for (const video of videos) {
        const videoStorageRef = ref(storage, `videoAulas/${video.video.name}`);
        const videoSnapshot = await uploadBytes(videoStorageRef, video.video);
        const videoDownloadURL = await getDownloadURL(videoSnapshot.ref);

        videosData.push({
          title: video.title,
          videoUrl: videoDownloadURL,
        });
      }

      const pdfStorageRef = ref(storage, `pdfAulas/${pdfAula.name}`);
      const pdfSnapshot = await uploadBytes(pdfStorageRef, pdfAula);
      const pdfDownloadURL = await getDownloadURL(pdfSnapshot.ref);

      const capaCursoStorageRef = ref(storage, `capasCursos/${capaCurso.name}`);
      const capaCursoSnapshot = await uploadBytes(capaCursoStorageRef, capaCurso);
      const capaCursoDownloadURL = await getDownloadURL(capaCursoSnapshot.ref);

      await addDoc(collection(firestore, 'videoAulas'), {
        title: videoAulaTitle,
        description: videoAulaDescription,
        videos: videosData,
        pdfUrl: pdfDownloadURL,
        capaCursoUrl: capaCursoDownloadURL,
        timestamp: serverTimestamp(),
      });

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setVideoAulaTitle('');
      setVideoAulaDescription('');
      setPdfAula(null);
      setCapaCurso(null);
      setVideos([{ title: '', video: null }]);
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingVideoAula(false);
    }
  };

  // Positive class

  const handleCoverChange = (e) => {
    setPositiveClassCover(e.target.files[0]);
  };
  
  const handlePositiveClassVideoChange = (e) => {
    setPositiveClassVideo(e.target.files[0]);
  };

  const handleUploadPositiveClass = async () => {
    if (!positiveClassTitle || !positiveClassDescription || !positiveClassCover || !positiveClassVideo || !positiveClassVideoTitle || uploadingPositiveClass) {
      setNotification({ message: 'Preencha todos os campos', type: 'warning' })
      return;
    }

    setUploadingPositiveClass(true);

    try {
      const storage = getStorage();
      const storageRefCover = ref(storage, `positiveClasses/${positiveClassCover.name}`);
      const storageRefVideo = ref(storage, `positiveClasses/${positiveClassVideo.name}`);

      const coverSnapshot = await uploadBytes(storageRefCover, positiveClassCover);
      const videoSnapshot = await uploadBytes(storageRefVideo, positiveClassVideo);

      const coverURL = await getDownloadURL(coverSnapshot.ref);
      const videoURL = await getDownloadURL(videoSnapshot.ref);

      const firestore = getFirestore();
      const positiveClassesRef = collection(firestore, 'positiveClasses');

      await addDoc(positiveClassesRef, {
        title: positiveClassTitle,
        description: positiveClassDescription,
        coverURL: coverURL,
        videoURL: videoURL,
        videoTitle: positiveClassVideoTitle,
        timestamp: serverTimestamp(),
      });

      setNotification({ message: 'Dados carregados com sucesso', type: 'success' })

      setPositiveClassTitle('');
      setPositiveClassDescription('');
      setPositiveClassCover(null);
      setPositiveClassVideo(null);
      setPositiveClassVideoTitle('');
    } catch (error) {
      setNotification({ message: 'Erro ao carregar dados', type: 'error' })
    } finally {
      setUploadingPositiveClass(false);
    }
  };


  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div className='container'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='padding' />
        
        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Vídeo de Boas-Vindas</h2>
            <h3>Escolher Video</h3>
            <input type="file" accept="video/*" onChange={handleVideoChange} required />
            <h3>Escolher Capa</h3>
            <input type="file" accept="image/*" onChange={handleCapaChange} required />

            <button
              onClick={handleUploadBoasVindas}
              disabled={uploading}
              style={{ backgroundColor: uploadingBoasVindas ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingBoasVindas? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>

        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Próximo Encontro</h2>
            <p className='info'>É aqui onde você posta o próximo encontro mostrado na Home.</p>
            <input
              type="text"
              placeholder="Título do Encontro"
              value={newEncontroTitle}
              onChange={(e) => setNewEncontroTitle(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Data do Encontro"
              value={newEncontroData}
              onChange={(e) => setNewEncontroData(e.target.value)}
              required
            />
            <input type="file" accept="image/*" onChange={(e) => setNewEncontroPhoto(e.target.files[0])} />
            <textarea
              placeholder="Descrição do Encontro"
              value={newEncontroDescription}
              onChange={(e) => setNewEncontroDescription(e.target.value)}
              required
            />
            <button
              onClick={handleUploadNewEncontro}
              disabled={uploadingNewEncontro}
              style={{ backgroundColor: uploadingNewEncontro ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingNewEncontro ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Vídeo</h2>
            <p className='info'>É aqui onde você posta o vídeo dos melhores momentos de cada encontro mostrado no menu Econtros.</p>
            <input type="file" accept="video/*" onChange={handleFileChange} />
            <input
              type="text"
              placeholder="Título do Vídeo"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              placeholder="Descrição do Vídeo"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <button
              onClick={handleUpload}
              disabled={uploading}
              style={{ backgroundColor: uploading ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploading ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar novo Álbum de Fotos</h2>
            <p className='info'>É aqui onde você posta as fotos dos encontros. visíveis no menu Encontros</p>
            <input
              type="text"
              placeholder="Nome do Álbum"
              value={album}
              onChange={handleAlbumChange}
            />
            <input type="file" accept="image/*" multiple onChange={handlePhotoChange} />
            <button
              onClick={handleUploadfotos}
              disabled={uploadingfoto}
              style={{ backgroundColor: uploadingfoto ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingfoto ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Novidades</h2>
            <p className='info'>É aqui onde você posta as novidades mostradas na Home</p>
            <input type="file" onChange={handleFileChangeNovidade} />
            <input
              type="text"
              placeholder="Título"
              value={titleNovidade}
              onChange={(e) => setTitleNovidade(e.target.value)}
            />
            <textarea
              placeholder="Descrição"
              value={descriptionNovidade}
              onChange={(e) => setDescriptionNovidade(e.target.value)}
            />
            <button
              onClick={handleUploadNovidade}
              disabled={uploadingNovidade}
              style={{ backgroundColor: uploadingNovidade ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingNovidade ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Curso</h2>
            <p className='info'>É aqui onde você posta os cursos.</p>
            <input
              type="text"
              placeholder="Título do curso"
              value={videoAulaTitle}
              onChange={(e) => setVideoAulaTitle(e.target.value)}
            />
            <textarea
              placeholder="Descrição do curso"
              value={videoAulaDescription}
              onChange={(e) => setVideoAulaDescription(e.target.value)}
            />
            <label>Escolha a Capa do curso</label>
            <input type="file" accept="image/*" onChange={handleFileChangeCapaCurso} />
            <label>Escolha a ferramenta em PDF</label>
            <input type="file" accept="application/pdf" onChange={handleFileChangePdfAula} />
            <label>Escolha a Videoaula</label>

            {videos.map((video, index) => (
              <div key={index} className='video-upload'>
                <label>Título do Vídeo</label>
                <input
                  type="text"
                  placeholder="Escreva o título do vídeo"
                  value={video.title}
                  onChange={(e) => handleVideoTitleChange(e, index)}
                />
                <label>Selecionar Vídeo</label>
                <input
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleVideoFileChange(e, index)}
                />
              </div>
            ))}
            <button className='btn-outros' onClick={addVideoField}>Adicionar Vídeo</button>


            <button
              onClick={handleUploadVideoAula}
              disabled={uploadingVideoAula}
              style={{ backgroundColor: uploadingVideoAula ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingVideoAula ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>

        <div className='content'>
          <div className='upload-form'>
            <h2>Carregar Positive Class</h2>
            <input
              type="text"
              placeholder="Título da Positive Class"
              value={positiveClassTitle}
              onChange={(e) => setPositiveClassTitle(e.target.value)}
            />
            <textarea
              placeholder="Descrição da Positive Class"
              value={positiveClassDescription}
              onChange={(e) => setPositiveClassDescription(e.target.value)}
            />
            <label>Escolha a Capa do Class</label>
            <input type="file" accept="image/*" onChange={handleCoverChange} />
            <label>Selecionar video</label>
            <input type="file" accept="video/*" onChange={handlePositiveClassVideoChange} />
            <input
              type="text"
              placeholder="Título do Vídeo"
              value={positiveClassVideoTitle}
              onChange={(e) => setPositiveClassVideoTitle(e.target.value)}
            />
            <button
              onClick={handleUploadPositiveClass}
              disabled={uploadingPositiveClass}
              style={{ backgroundColor: uploadingPositiveClass ? 'green' : '#ca60d6', color: 'white' }}
            >
              {uploadingPositiveClass ? 'Carregando...' : 'UPLOAD'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Administrador;
