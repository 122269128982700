import React, { useState, useEffect } from 'react';
import './ranking.css';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Avatar from '../../assets/img/woman.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

function Ranking() {
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dataCarregada, setDataCarregada] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const db = getFirestore();
        const userCollections = ['userBasic', 'userPremium', 'userMasters'];

        const allUsers = [];
        for (const collectionName of userCollections) {
          const userQuery = query(collection(db, collectionName));
          const querySnapshot = await getDocs(userQuery);

          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            const userName = userData.nome;
            allUsers.push({ idAuth: userData.idAuth, name: userName, score: 0 });
          });
        }

        setUsers(allUsers);
        setDataCarregada(true);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    fetchUsers();
  }, []);

  const updateUserScores = async (collectionName, fieldName, multiplier) => {
    try {
      const db = getFirestore();
      const parentCollection = collection(db, collectionName);
      const parentDocs = await getDocs(parentCollection);

      const updatedUsers = [...users];

      for (const parentDoc of parentDocs.docs) {
        const idParentDoc = parentDoc.id;

        const childCollection = collection(db, collectionName, idParentDoc, fieldName);
        const childDocs = await getDocs(childCollection);

        for (const childDoc of childDocs.docs) {
          const userId = childDoc.id;
          const completedItems = Object.values(childDoc.data().completedVideos || {}).reduce(
            (count, value) => (value === true ? count + 1 : count),
            0
          );

          const userIndex = updatedUsers.findIndex((user) => user.idAuth === userId);

          if (userIndex !== -1) {
            updatedUsers[userIndex].score += completedItems * multiplier;
          }
        }
      }

      setUsers(updatedUsers);
    } catch (error) {
      console.error(`Erro ao calcular pontuações de ${collectionName}:`, error);
    }
  };

  useEffect(() => {
    const calculateScores = async () => {
      if (!dataCarregada) return;

      // Atualizar pontuações para `videoAulas`
      await updateUserScores('videoAulas', 'userCompletions', 100);

      // Atualizar pontuações para `positiveClasses`
      await updateUserScores('positiveClasses', 'userCompletions', 100);
    };

    calculateScores();
  }, [dataCarregada]);

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="container">
        <Header toggleSidebar={toggleSidebar} />
        <div className="padding" />
        <div className="ranking-container content">
          <h2>Ranking de Pontuações</h2>
          <center>Confira quem está no top 10 das Positivas!</center>
          <div className="usuarios-table">
            <table>
              <thead>
                <tr>
                  <th>Colocação</th>
                  <th></th>
                  <th><FontAwesomeIcon icon={faMedal} style={{ fontSize: '25px' }} /></th>
                </tr>
              </thead>
              <tbody>
                {users
                  .sort((a, b) => b.score - a.score)
                  .slice(0, 10)
                  .map((user, index) => (
                    <tr key={index} className="user-row">
                      <td>{index + 1}º</td>
                      <td>
                        <img src={user.avatar || Avatar} alt="Avatar" />
                        {user.name}
                      </td>
                      <td>{user.score}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ranking;